@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.home-container {
  position: relative;
  height: 100vh;
  background: url('/public/img/soldadohome.jpg') no-repeat center center/cover;
  display: flex;
  justify-content: center; /* Centraliza verticalmente */
  align-items: center; /* Centraliza horizontalmente */
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Escurece o plano de fundo */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centraliza todos os elementos no meio */
  align-items: center;
  color: #fff;
  text-align: center;
}

/* Estilos e animação da logo */
.animated-logo {
  width: 120px; /* Tamanho padrão */
  margin-bottom: 10px; /* Espaço entre logo e texto */
  animation: bounceIn 1.5s ease-in-out infinite alternate; /* Animação contínua */
}

/* Animação da logo */
@keyframes bounceIn {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px; /* Espaço maior entre logo e outros textos */
}

.logo-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  margin-top: 10px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  text-transform: uppercase; /* Torna o texto MAIÚSCULO */
}

.welcome-title {
  font-size: 2.5rem; /* Ajustei o tamanho */
  font-weight: 700;
  margin: 20px 0; /* Espaço vertical */
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.welcome-description {
  font-size: 1.2rem;
  margin-bottom: 40px; /* Espaço maior entre descrição e botão */
  padding: 0 10px;
}

.custom-button {
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, background 0.3s, box-shadow 0.3s;
}

.custom-button:hover {
  transform: scale(1.1);
  background: linear-gradient(90deg, #0072ff, #00c6ff);
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.5);
}

/* Responsividade */
@media (max-width: 768px) {
  .welcome-title {
    font-size: 2rem;
  }

  .welcome-description {
    font-size: 1rem;
  }

  .custom-button {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .animated-logo {
    width: 90px; /* Ajusta tamanho da logo */
  }
}

@media (max-width: 480px) {
  .welcome-title {
    font-size: 1.5rem;
  }

  .welcome-description {
    font-size: 0.9rem;
  }

  .custom-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .animated-logo {
    width: 70px; /* Tamanho menor para smartphones */
  }
}

.welcome-content {
  animation: fadeIn 1s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
