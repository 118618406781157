.preenche-pagina {
    min-height: 80vh;
    overflow: auto;
  }
  
  .banner-container {
    width: fit-content;
    margin-bottom: 1em;
  }

/* Efeito de hover e toque nos botões dos testes */
.banner-container {
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animação suave */
  }
  
  .banner-container:hover,
  .banner-container:focus,
  .banner-container:active {
    transform: scale(1.05); /* Aumenta levemente o botão */
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.3); /* Intensifica a sombra */
  }
  
  /* Imagem dentro dos botões com efeito */
  .banner-container img {
    transition: transform 0.3s ease; /* Garante animação suave para a imagem */
  }
  
  .banner-container img:hover,
  .banner-container img:focus,
  .banner-container img:active {
    transform: scale(1.05); /* Aumenta levemente a imagem */
  }

  /* Simulação de hover para dispositivos móveis */
@media (hover: none) and (pointer: coarse) {
    .banner-container:active {
      transform: scale(1.05); /* Aumenta levemente o botão */
      box-shadow: 0 12px 16px rgba(0, 0, 0, 0.3); /* Intensifica a sombra */
      transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animação suave */
    }
  
    .banner-container img:active {
      transform: scale(1.05); /* Aumenta levemente a imagem */
    }
  }
  
  .sombra-forte {
    -webkit-box-shadow: 10px 10px 0px -7px rgba(133, 128, 133, 1);
    -moz-box-shadow: 10px 10px 0px -7px rgba(133, 128, 133, 1);
    box-shadow: 10px 10px 0px -7px rgba(133, 128, 133, 1);
    border: 1px solid rgb(201, 201, 201);
  }
  
  /* Botão de Apagar Respostas */
  .button-danger {
    background-color: #e53935 !important;
    color: #ffffff !important;
    font-weight: bold;
    text-transform: uppercase;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
  
  .button-danger:hover {
    background-color: #b71c1c !important;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .button-danger:active {
    background-color: #9c1a17 !important;
    transform: translateY(1px);
  }
  
  /* Centralização dos Banners */
  #banners-div {
    padding-top: 2rem;
  }
  
  .d-flex {
    display: flex;
  }
  
  .justify-content-center {
    justify-content: center;
  }
  
  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .rounded {
    border-radius: 0.25rem;
  }
  