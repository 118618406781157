
/* Contêiner principal */
.result-container {
    width: 100vw; /* Ocupa toda a largura da janela */
    padding: 0; /* Remove espaçamento lateral */
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  /* Estilos para cada seção */
  .result-section {
    border: 1px solid #d1d5db;
    border-radius: 8px;
    padding: 16px;
    background-color: #f9fafb;
    width: 100%; /* Preenche 100% da largura do contêiner */
    margin: 10px 0; /* Espaçamento entre seções */
  }
  
  /* Títulos das seções */
  .result-section h3 {
    background-color: #1d4ed8;
    color: white;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    margin: 0;
  }
  
  /* Estilos da tabela */
  .MuiTable-root {
    width: 100% !important; /* Ocupa toda a largura da seção */
    border-collapse: collapse;
  }
  
  /* Células da tabela */
  .MuiTableCell-root {
    border: 1px solid #d1d5db !important; /* Bordas das células */
    padding: 10px !important;
    text-align: left;
  }
  
  /* Alinhamento do intervalo */
  .interval-cell {
    text-align: center;
  }
  /* Faz o contêiner principal preencher a largura total */
.result-container {
    width: 100vw; /* Largura total da janela do navegador */
    padding: 0; /* Remove padding interno */
    margin: 0; /* Remove margens */
    box-sizing: border-box; /* Inclui padding e bordas no cálculo da largura */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Estilo para cada seção */
  .result-section {
    border: 1px solid #d1d5db;
    border-radius: 8px;
    padding: 16px;
    background-color: #f9fafb;
    width: 90%; /* Faz as seções ocuparem 90% da largura da página */
    max-width: 1200px; /* Limita a largura máxima para telas grandes */
    margin: 20px auto; /* Centraliza as seções */
  }
  
  /* Títulos das seções */
  .result-section h3 {
    background-color: #1d4ed8;
    color: white;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    margin: 0;
  }
  
  /* Faz as tabelas ocuparem 100% da largura das seções */
  .MuiTable-root {
    width: 100% !important; /* Garante largura total */
    border-collapse: collapse; /* Remove espaçamento entre bordas */
  }
  
  /* Células da tabela */
  .MuiTableCell-root {
    border: 1px solid #d1d5db !important; /* Adiciona bordas */
    padding: 10px !important;
    text-align: left;
  }
  
  /* Alinhamento para intervalo de pontuação */
  .interval-cell {
    text-align: center;
  }
/* Reseta margens e paddings globais */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Faz o contêiner principal ocupar toda a largura da página */
  .result-container {
    width: 100vw; /* Ocupa 100% da largura da viewport */
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza as seções */
    background-color: #f3f4f6; /* Cor de fundo opcional */
  }
  
  /* Cada seção ocupa toda a largura */
  .result-section {
    border: 1px solid #d1d5db;
    border-radius: 8px;
    padding: 16px;
    background-color: #ffffff;
    width: 100%; /* Garante 100% da largura */
    max-width: 1200px; /* Limita a largura máxima em telas grandes */
    margin: 20px 0; /* Espaçamento entre as seções */
  }
  
  /* Títulos das seções */
  .result-section h3 {
    background-color: #1d4ed8;
    color: white;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 16px;
  }
  
  /* Faz as tabelas ocuparem toda a largura */
  .result-table {
    width: 100%; /* Largura total da seção */
    border-collapse: collapse; /* Remove espaçamento entre bordas */
  }
  
  /* Bordas e espaçamento interno nas células */
  .result-table th,
  .result-table td {
    border: 1px solid #d1d5db;
    padding: 10px;
    text-align: left;
  }
  
  /* Centraliza as células de intervalo */
  .result-table .interval-cell {
    text-align: center;
  }
/* Centraliza o texto nas células de classificação */
    .result-table .classification-cell {
    text-align: center; /* Centraliza o texto horizontalmente */
    vertical-align: middle; /* Centraliza o texto verticalmente */
  }    