@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');


html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.background-title {
  background-color: #001321; /* Azul escuro */
  width: 100%;
  padding: 1rem 0; /* Espaçamento interno */
}

.chackra-petch {
  font-family: 'Chakra Petch', sans-serif;
  font-size: 0.7em;
}
.chackra-petch {
  font-family: 'Chakra Petch', sans-serif;
  font-size: 0.7em;
}

.container-relative {
  position: relative;
}

.bg-error {
  background-color: rgb(158, 38, 58);
}

.muito-baixo {
  color: rgb(53, 53, 252);
}

.imagem-especular {
  transform: scaleX(-1);
}

.background-title {
  background-color: rgb(0, 19, 33);
}

.baixo {
  color: rgb(80, 184, 80);
}

.medio {
  color: rgb(184, 184, 80);
}

.alto {
  color: rgb(255, 0, 0);
}

.muito-alto {
  color: purple;
}

.red {
  color: red;
}

.sombra-forte {
  /* MOVIDO PARA Home.css */
}

.bg-neuroticismo {
  background-color: rgb(91, 145, 10) !important;
  font-size: medium;
  font-weight: bold;
  color: white !important;
}

.bg-masculino {
  background-color: rgb(6, 26, 141) !important;
}

.bg-masculino-light {
  background-color: rgb(192, 241, 253) !important;
}

.bg-feminino {
  background-color: rgb(133, 2, 133);
}

.bg-feminino-light {
  background-color: rgb(252, 184, 252) !important;
}

.value-indicator {
  position: absolute;
  top: -20px;
  color: white;
  font-weight: bold;
  z-index: 2;
}

.bg-extroversao {
  background-color: rgb(202, 199, 37) !important;
  font-size: medium;
  font-weight: bold;
  color: white;
}

.bg-abertura {
  background-color: rgb(37, 40, 202) !important;
  font-size: medium;
  font-weight: bold;
  color: white;
}

.bg-amabilidade {
  background-color: rgb(202, 37, 37) !important;
  font-size: medium;
  font-weight: bold;
  color: white;
}

.bg-conscienciosidade {
  background-color: rgb(133, 2, 133) !important;
  font-size: medium;
  font-weight: bold;
  color: white;
}

.title {
  background-image: url("/public/img/banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.slogan {
  font-size: 1rem;
  margin-left: 0.5rem;
}

@media (min-width: 576px) {
  .slogan {
    font-size: 1rem;
    margin-left: 0.5rem;
  }
}

@media (min-width: 768px) {
  .slogan {
    font-size: 2.5rem;
    margin-left: 0.5rem;
  }
}

@media (min-width: 992px) {
  .slogan {
    font-size: 3rem;
    margin-left: 1rem;
  }
}

@media (min-width: 1200px) {
  .slogan {
    font-size: 3.5rem;
    margin-left: 2rem;
  }
}

.preenche-pagina {
  /* MOVIDO PARA Home.css */
}

.image-container {
  position: relative;
  width: calc(15rem + 15vw);
  height: 50px;
}

.banner-container {
  /* MOVIDO PARA Home.css */
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.card {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
}

.card img {
  width: 100%;
  height: auto;
}

.button-danger {
  /* MOVIDO PARA Home.css */
}

.table-hover tbody tr:hover {
  background-color: #f8f9fa;
}

.text-success {
  color: #28a745 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-primary {
  color: #007bff !important;
}

.bg-light {
  background-color: #f0f0f0;
}

.fw-bold {
  font-weight: bold;
}

.table thead th {
  background-color: #007bff;
  color: #fff;
  text-align: center;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.highlight-row {
  background-color: #f0f8ff;
  font-weight: bold;
  border: 2px solid #007bff;
}

.highlight-row .text-primary {
  color: #007bff;
}

.highlight-row .text-success {
  color: #28a745;
}

.highlight-row .text-danger {
  color: #dc3545;
}

/* Certifique-se de que o rodapé esteja sempre no rodapé da página */
body, html {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

.container {
  flex: 1; /* Faz com que o conteúdo ocupe o espaço disponível */
}

footer {
  background-color: #001321; /* Pode ajustar a cor do rodapé */
  color: white;
  text-align: center;
  padding: 1rem 0;
  position: relative; /* Ajuste se necessário */
  bottom: 0;
  width: 100%;
}

/* Centralizar a tabela */
.table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto; /* Espaçamento automático */
  width: 90%; /* Ajuste a largura conforme necessário */
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

/* Responsividade */
@media (max-width: 768px) {
  .table-container table {
    font-size: 0.9rem; /* Ajuste o tamanho da fonte */
  }

  .table-container {
    width: 100%; /* Usa toda a largura da tela */
  }
}
.card-header {
  padding: 1rem;
  border-radius: 0.25rem 0.25rem 0 0;
}

.table-cell {
  padding: 0.5rem;
}

.text-justify {
  text-align: justify;
}

.bg-masculino {
  background-color: #007bff; /* Example color, adjust as needed */
}

.mt-4 {
  margin-top: 1.5rem;
}

.rounded {
  border-radius: 0.25rem;
}

.h5 {
  font-size: 1.25rem;
  font-weight: 500;
}

.text-light {
  color: #f8f9fa;
}

